import React from 'react'
import {Switch, Route} from 'react-router-dom'

import List from './List'
import New from './New'
import View from './View'

export default function Reports(props) {

	return(
		<>
			<Switch>
				<Route exact path={['/reports', '/reports/list']} component={List} />
				<Route path={'/reports/new'} component={New} />
				<Route path={'/reports/:rid?'} component={View} />
			</Switch>
		</>
		
	)
}