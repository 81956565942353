import moment from 'moment'
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateUserPersonal} from '../../../actions/user'


export default function Personal() {

	const dispatch 	= useDispatch()

	
	const user					= useSelector(state => state.user)
	const {data: lang} 	= useSelector(state => state.language)
	const projects 		= useSelector(state => state.projects) 

	let project  = projects.list.find(item => item.objectId === user.project) || {}

	const [state, changeState] = useState({
		name:				user.name,
		phone:			user.phone,
		project: 		project.name || lang.not_assigned,
		createdAt:	user.createdAt
	})

	const setState = data => changeState(prevState => ({...prevState, ...data}))
	
	const onChange = e => setState({[e.target.name]: e.target.value})

	const onSubmit = () => dispatch(updateUserPersonal({name: state.name, phone: state.phone}))


	return(
		<div className="box p-3 mb-3">

				<div className="row">

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.name}</span>
							<input type="text" className="form-control"  name="name" value={state.name} onChange={onChange} />
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.phone}</span>
							<input type="text" className="form-control"  name="phone" value={state.phone} onChange={onChange}  />
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.project}</span>
							<input type="text" className="form-control" disabled  name="name" defaultValue={state.project} />
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.created_at}</span>
							<input type="text" className="form-control" disabled  name="phone" defaultValue={moment(user.createdAt?.toMillis()).format('DD MMMM YYYY')}  />
						</div>
					</div>

					<div className="col-12 text-end">
						<button className="btn btn-success" disabled={user.loading || !state.name.length} onClick={onSubmit}>
							{user.loading ? <i className="fas fa-spin fa-spinner-third" /> : lang.save}
						</button>
					</div>

				</div>	
		</div>
	)
}