import React from 'react'
import moment from 'moment'
import {useSelector} from 'react-redux'


export default function View(props) {

	const {data: lang} 	= useSelector(state => state.language)
	const projects		= useSelector(state => state.projects)
	const reports				= useSelector(state => state.reports)
	const report 				= reports.list.length ? reports.list[0] : {}

	let project 				= projects?.list.find(item => item.objectId === report?.project) || {}
	let totalHours = report.monday + report.tuesday + report.wednesday + report.thursday + report.friday + report.saturday + report.sunday || 0

	return(
		<>

			<ul className="nav nav-tabs">
				<li className="nav-item">
					<span className="nav-link active text-capitalize fw-bold">{lang.last_report}</span>
				</li>
			</ul>

			<div className="box p-3" style={{flex:1}}>
				
				<div className="" style={{marginBottom: 10}}>
					<h5 style={{margin: 0}}> <b>{report.period}</b></h5>
					<b className="text-muted">{project.name || lang.no_report}</b>
				</div>


				<hr />

				{report.objectId &&
					<div className="row mt-2">

						<div className="col-12  row">

							<div className="col-6">

								<b>{lang.monday}</b>: 
								<br/>
								<b>{lang.tuesday}</b>: 
								<br/>
								<b>{lang.wednesday}</b>: 
								<br/>
								<b>{lang.thursday}</b>: 
								<br/>
								<b>{lang.friday}</b>: 
								<br/>
								<b>{lang.saturday}</b>:
								<br/>
								<b>{lang.sunday}</b>:

							</div>

							<div className="col-6 text-end text-md-start">

								{report.monday} {lang.hours}
								<br/>
								{report.tuesday} {lang.hours}
								<br/>
								{report.wednesday} {lang.hours}
								<br/>
								{report.thursday} {lang.hours}
								<br/>
								{report.friday} {lang.hours}
								<br/>
								{report.saturday} {lang.hours}
								<br/>
								{report.sunday} {lang.hours}

							</div>

						</div>

						<div className="col-12 mt-3 row">

							<div className="col-6">

								<b>{lang.total_hours}</b>:
								<br/>
								<b>{lang.project}</b>: 
								<br/>
								<b>{lang.created_at}</b>:

							</div>

							<div className="col-6 text-end text-md-start">

								{totalHours} {lang.hours}
								<br/>
								{project.name}
								<br/>
								{moment(report.createdAt?.toMillis()).format('DD MMMM YYYY')}

							</div>

						</div>

					</div>
				}

				{!report.objectId && 
					<div className="text-danger">
						{lang.no_report_register}
					</div>
				}
			</div>
		</>	
	)
}