import React, {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {logout} from '../../actions/user'

export default function Logout() {

	const dispatch = useDispatch()
	const timeout = useRef()
	
	useEffect(() => {

		dispatch(logout(true))
		timeout.current = setTimeout(() => {window.location.href = '/' }, 1500 )

		return () => clearTimeout(timeout.current)

	}, [dispatch])
	
	return (
		<div className="loader-wrapper">
			<div className="loader" />
		</div>
	)
}