import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {logout} from '../../actions/user'

export default function TopBar(props) {

	const dispatch = useDispatch()

	const user = useSelector(state => state.user)
	const {data: lang} = useSelector(state => state.language)

	return(
		<div className="box p-2 d-flex mb-3 align-items-center">

			<div className="ps-2">{lang.welcome}, <b>{user.name}</b></div>

			<div className="separator" />

			<button className="btn btn-sm btn-danger" onClick={() => dispatch(logout())}>{lang.logout}</button>

		</div>
	)
}