/************************************************
 * IMPORTS
 ***********************************************/

 import firebase from 'firebase/app'
 import 'firebase/auth'
 import 'firebase/storage'
 import 'firebase/database'
 import 'firebase/firestore'
 
 import {
	 FIREBASE_CONFIG
 } from '../constants'
 
 /************************************************
	* INIT FIREBASE
	***********************************************/
 
 firebase.initializeApp(FIREBASE_CONFIG)
 
 
 /************************************************
	* Firebase authentication instance
	***********************************************/
 
 export const auth = firebase.auth()
 
 
 /************************************************
	* Firebase database instance
	***********************************************/
 
 export const db = firebase.firestore()
 
 
 /************************************************
	* Firebase storage instance
	***********************************************/
 
 export const storage = firebase.storage()
 
 
 /************************************************
	* Firebase realtime instance
	***********************************************/
 
 export const realtime = firebase.database()
 
 
 /************************************************
	* Server timestamp placeholder
	***********************************************/
 
 export const serverTime = firebase.firestore.FieldValue.serverTimestamp()
 
 
 /************************************************
	* Create timestamp with client data
	***********************************************/
 
 export const clientTime = firebase.firestore.Timestamp
 
 
 /************************************************
	* Export field values to work with
	***********************************************/
 
 export const fieldValue = firebase.firestore.FieldValue