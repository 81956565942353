import React,{useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {updatePassword} from '../../../actions/user'


export default function Security() {

	const dispatch 	= useDispatch()

	const [state, changeState] = useState({
		password:			'',
		rePassword:		'',
	})
	
	const user					= useSelector(state => state.user)
	const {data: lang} 	= useSelector(state => state.language)

	const setState = data => changeState(prevState => ({...prevState, ...data}))
	
	const onChange = e => setState({[e.target.name]: e.target.value})

	const onSubmit = () => dispatch(updatePassword(state.password, state.rePassword))


	return(
		<div className="box p-3 mb-3">

				<div className="row">

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.new_password}</span>
							<input type="password" className="form-control"  name="password" value={state.password} onChange={onChange} />
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.re_password}</span>
							<input type="password" className="form-control"  name="rePassword" value={state.re_password} onChange={onChange}  />
						</div>
					</div>

					<div className="col-12 text-end">
						<button className="btn btn-success" disabled={user.loading || !state.password.length || !state.rePassword.length} onClick={onSubmit}>
							{user.loading ? <i className="fas fa-spin fa-spinner-third" /> : lang.save}
						</button>
					</div>

				</div>	
		</div>
	)
}