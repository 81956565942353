/************************************************
* IMPORTS
*************************************************/

import React,{useEffect} from 'react'
import ReactDOM from 'react-dom'
import {Router, Route, Switch, Redirect} from 'react-router-dom'
import {Provider, useDispatch, useSelector} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import {history} from './utils'
import store from './reducers'

import {
	detectLoginStatus
} from './actions/user'

/************************************************
* STYLES
************************************************/
import 'react-toastify/dist/ReactToastify.min.css'

/************************************************
* COMPONENTS
************************************************/

import Home from './components/Home'
import Connect from './components/Connect'
import Reports from './components/Reports'
import Logout from './components/Logout'
import TopBar from './components/TopBar'
import SideBar from './components/SideBar'
import MyReports from './components/MyReports'
import Account from './components/Account'
import Projects from './components/Projects'
import Employee from './components/Employee'


/************************************************
* INIT
************************************************/


const App = () => {

	const dispatch = useDispatch()

	const user = useSelector(state => state.user)

	useEffect(() => {
	 dispatch(detectLoginStatus())
	},[dispatch])

	if (!user.objectId)
		return <Connect />

	return (
		<div className="container-fluid py-3">
			<Redirect from="/index.html" to="/" />
			<Route path="/logout" component={Logout} />

			<div className="section content">

					<TopBar />

					<div className="row">

						<div className="col-12 col-md-3">
							<SideBar />
						</div>

						<div className="col-12 col-md-9">
							<Switch>
								<Route exact path={['/']} component={Home} />
								<Route path ='/account' component={Account} />

								{user.admin ? <Switch>
									<Route path ='/employees/:uid?' component={Employee} />
									<Route path ='/reports/:rid?' component={Reports} />
									<Route path ='/projects/:cid?' component={Projects}/>
								</Switch> : <Switch> 
									<Route path ='/my_reports/:rid?' component={MyReports} />
								</Switch>}

							</Switch>
						</div>

					</div>
		
			</div>

		</div>
	)
}


/************************************************
* Render the application
************************************************/
ReactDOM.render(
	<Router history={history}>
		<Provider store={store}>
			<App />
			<ToastContainer/>
		</Provider>
	</Router>,
	document.getElementById('app')
)