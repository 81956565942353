import React from 'react'
import {Switch, Route} from 'react-router-dom'

import List from './List'
import View from './View'
import Create from './Create'

export default function Employee(props) {

	return(
		<>
			<Switch>
				<Route exact path={['/employees', '/employees/list']} component={List} />
				<Route exact path='/employees/create' component={Create} />
				<Route path={`/employees/:uid?`} component={View} />
			</Switch>
		</>
		
	)
}