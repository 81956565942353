import React,{useState} from 'react'
import moment from 'moment'
import {useSelector, useDispatch} from 'react-redux'
import {fetchMyReports} from '../../../actions/user'
import Preview from '../Preview'

export default function List(props) {

	const dispatch = useDispatch()

	const {data: lang} 	= useSelector(state => state.language)
	const reports 			= useSelector(state => state.reports)
	const projects			= useSelector(state => state.projects)

	const [preview, setPreview] = useState({})

	if (preview.objectId) 
		return <Preview item={preview} onBack={() => setPreview({})} />
		
	return(
		<>

			<ul className="nav nav-tabs">
				<li className="nav-item">
					<span className="nav-link active text-capitalize fw-bold">{lang.my_reports}</span>
				</li>
				
				<div className="separator" />
					
			</ul>

			<div className="box mb-3">

				<div className="list-group-flush">

					{reports.list?.map(report => {

						let totalHours = report.monday + report.tuesday + report.wednesday + report.thursday + report.friday + report.saturday + report.sunday
						let project = projects.list.find(item => item.objectId === report.project) || {}

						return(
							<div className="list-group-item align-items-center pointer" key={report.objectId}  onClick={()=> setPreview(report)}>
								
								<div className="row">

									<div className="col-9 col-sm-6 col-md-6 mb-2 mb-md-0 d-grid align-items-center">
										<div className="text-primary"><b>{report.period}</b></div>
										<div className="text-muted">{totalHours} {lang.hours}</div>
									</div>

									<div className="col-12 order-2 order-md-1 col-md-3 mb-2 mb-md-0 d-grid align-items-center">
										<small><b>{project?.name}</b></small>
									</div>

									<div className="col-3 order-1 order-md-3 col-sm-6 col-md-3 mb-2 mb-md-0 d-grid align-items-center justify-content-end flex-grow-0">
										<small>{moment(report.createdAt.toMillis()).format('DD MMMM YYYY')}</small>
									</div>

								</div>

							</div>
						)
					})}

					{(!reports.list.length && reports.noMoreToLoad) &&
						<div className="list-group-item text-danger">{lang.no_reports}</div>
					}

					{!reports.noMoreToLoad &&
						<div className="list-group-item text-center">
							<button className="btn btn-sm btn-primary" onClick={() => dispatch(fetchMyReports())} disabled={reports.loading}>
								{reports.loading ? <i className="fas fa-spin fa-spinner-third" /> : lang.load_more}
							</button>
						</div>
					}

				</div>

			</div>

		</>	
	)
}