import {
	USERS_RESET,
	USERS_LOADED,
  USERS_LOADING,
	USERS_NEW_ITEM,
	USERS_LOADED_ALL,
	USERS_LOADED_MORE,
	USERS_UPDATED_ITEM
} from '../constants'

const initialState = {
	loading: false,
  list: []
}

export default function Users(state = initialState, action) {

  switch (action.type) {
    case USERS_LOADED:
      return {
				...state,
        loading: false,
        list: action.data || []
     }
    case USERS_LOADING:
      return {
        ...state,
        loading: true
			}
		case USERS_LOADED_MORE:
			return {
				...state,
				loading: false,
				list: [
					...state.list,
					...action.data
				]
			}
		case USERS_LOADED_ALL:
			return {
				...state,
				loading: false,
				noMoreToLoad: true
			}
    case USERS_NEW_ITEM:
      return {
        ...state,
				list: [action.data, ...state.list]
			}
		case 	USERS_UPDATED_ITEM:
			return {
				...state,
				list: state.list.map(user => {
					if (user.objectId === action.data.objectId)
						return {...user, ...action.data}

					return user					
				})
			}
		case USERS_RESET:	
			return initialState	
    default:
      return state
	}

}