import React from 'react'
import moment from 'moment'
import {useSelector} from 'react-redux'


export default function View(props) {

	const {data: lang} 	= useSelector(state => state.language)
	const categories		= useSelector(state => state.categories)
	const reports = props.items


	return(
		<>
			<div className="columns" style={{marginBottom: 10}}>
				<i className="fas fa-chevron-left" onClick={() => props.onBack()} style={{cursor: 'pointer', padding: 10, marginRight: 5}} />
				<h5 style={{margin: 0}}><b>{props.user}</b></h5>
			</div>
	
			{reports.map(report => {

				let category 				= categories?.list.find(item => item.objectId === report?.category) || {}
				let totalHours = report.monday + report.tuesday + report.wednesday + report.thursday + report.friday + report.saturday + report.sunday


				return	<div className="box p-3 mb-3" key={report.objectId}>
					
					<h5><b>{report.period}</b></h5>
					<hr/>

					<div className="row mt-2">

						<div className="col-12 col-md-6 row">

							<div className="col-6">

								<b>{lang.monday}</b>: 
								<br/>
								<b>{lang.tuesday}</b>: 
								<br/>
								<b>{lang.wednesday}</b>: 
								<br/>
								<b>{lang.thursday}</b>: 
								<br/>
								<b>{lang.friday}</b>: 
								<br/>
								<b>{lang.saturday}</b>:
								<br/>
								<b>{lang.sunday}</b>:

							</div>

							<div className="col-6 text-end text-md-start">

								{report.monday} {lang.hours}
								<br/>
								{report.tuesday} {lang.hours}
								<br/>
								{report.wednesday} {lang.hours}
								<br/>
								{report.thursday} {lang.hours}
								<br/>
								{report.friday} {lang.hours}
								<br/>
								{report.saturday} {lang.hours}
								<br/>
								{report.sunday} {lang.hours}

							</div>

						</div>

						<div className="col-12 col-md-6 mt-3 mt-md-0 row">

							<div className="col-6">

								<b>{lang.total_hours}</b>:
								<br/>
								<b>{lang.category}</b>: 
								<br/>
								<b>{lang.created_at}</b>:

							</div>

							<div className="col-6 text-end text-md-start">
								
								{totalHours} {lang.hours}
								<br/>
								{category.name}
								<br/>
								{moment(report.createdAt.toMillis()).format('DD MMMM YYYY')}

							</div>

						</div>

					</div>
				</div>
				})}

		</>
	)
}