import React,{useEffect} from 'react'
import moment from 'moment'
import {useSelector,useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {fetchProjects} from '../../../actions/user'


export default function View(props) {

	const {rid} = useParams()
	const dispatch = useDispatch()

	const reports 			= useSelector(state => state.reports)
	const {data: lang} 	= useSelector(state => state.language)
	const projects			= useSelector(state => state.projects)

	const report 				= reports.list.find(item => item.objectId === rid) || {}

	useEffect(() => {
		dispatch(fetchProjects(rid))
	},[dispatch, rid])


	if (!report.objectId)
		return null

	let category 				= projects?.list.find(item => item.objectId === report?.category) || {}
	let totalHours		 = report.monday + report.tuesday + report.wednesday + report.thursday + report.friday + report.saturday + report.sunday

	return(
		<div className="box p-3">

			<h4>{report.week}</h4>

			<hr />

			<div className="row mt-2">

				<div className="col-12 col-md-6">
					<b>{lang.monday}</b>: {report.monday} {lang.hours}
					<br/>
					<b>{lang.tuesday}</b>: {report.tuesday} {lang.hours}
					<br/>
					<b>{lang.wednesday}</b>: {report.wednesday} {lang.hours}
					<br/>
					<b>{lang.thursday}</b>: {report.thursday} {lang.hours}
					<br/>
					<b>{lang.friday}</b>: {report.friday} {lang.hours}
					<br/>
					<b>{lang.saturday}</b>: {report.saturday} {lang.hours}
					<br/>
					<b>{lang.sunday}</b>: {report.sunday} {lang.hours}
				</div>

				<div className="col-12 col-md-6">
					<b>{lang.total_hours}</b>: {totalHours} {lang.hours}
					<br/>
					<b>{lang.category}</b>: {category.name}
					<br/>
					<b>{lang.created_at}</b>: {moment(report.createdAt.toMillis()).format('DD MMMM YYYY')}
				</div>

			</div>

		</div>
	)
}