import React from 'react'
import { useSelector } from 'react-redux'
import {Switch, Route, Redirect, NavLink} from 'react-router-dom'

import Presonal from './Personal'
import Security from './Security'

export default function Account() {

	const {data: lang} 	= useSelector(state => state.language)

	return(
		<>
			<ul className="nav nav-tabs">
				<li className="nav-item">
					<NavLink to='/account/personal' className="nav-link text-capitalize fw-bold">{lang.personal}</NavLink>
				</li>
				<li className="nav-item">
					<NavLink to='/account/security' className="nav-link text-capitalize fw-bold">{lang.security}</NavLink>
				</li>
			</ul>

			<Switch>
				<Route exact path="/account/personal" component={Presonal} />
				<Route exact path="/account/security" component={Security} />
				<Redirect from="/account" to="/account/personal" />
			</Switch>
		</>
		
	)

}