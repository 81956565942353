import React from 'react'
import {Switch, Route} from 'react-router-dom'

import Login from './Login'

export default function Conenct() {
	return(
		<Switch>
			<Route path={['/', '/login']} component={Login} />
		</Switch>
	)
}