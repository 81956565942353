import React,{useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'
import {updateUser} from '../../../../actions/user'


export default function View(props) {

	const dispatch 	= useDispatch()

	const {uid}			= useParams()
	
	const user					= useSelector(state => state.user)
	const {data: lang, list} 	= useSelector(state => state.language)
	const projects 			= useSelector(state => state.projects) 
	const users 				= useSelector(state => state.users)

	const [state, changeState] = useState({
		email: 			'',
		name:				'',
		phone:			'',
		project:		'',
		blocked:		'',
		lang:				'',
		admin:		false
	})


	const setState = data => changeState(prevState => ({...prevState, ...data}))
	
	const onChange = e => setState({[e.target.name]: e.target.value})

	const onSubmit = () => dispatch(updateUser(state))

	useEffect(() => {
		const currentUser		= users.list.find(item => item.objectId === uid ) || {}
		setState({...currentUser})
	}, [uid, users.list])

	return(
		<>

			<div className="box p-3 mb-3">

				<div className="row">

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.email}</span>
							<input type="email" className="form-control"  name="email" defaultValue={state.email} disabled />
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.project}</span>
							<select className="form-control" name="project" value={state.project} onChange={onChange}>
								<option value="">Not assigned</option>
								{projects.list.map(project => <option value={project.objectId} key={project.objectId}>{project.name}</option>)}
							</select>
						</div>
					</div>


					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.name}</span>
							<input type="text" className="form-control"  name="name" value={state.name} onChange={onChange}  />
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.phone}</span>
							<input type="text" className="form-control"  name="phone"  value={state.phone} onChange={onChange} />
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.blocked}</span>
							<select className="form-control" name="blocked" value={state.blocked} onChange={onChange}>
								<option value={true}>Yes</option>
								<option value={false}>No</option>
							</select>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.language}</span>
							<select className="form-control" name="lang" value={state.lang} onChange={onChange}>
								{list.map(item => <option key={item} value={item}>{item}</option>)}
							</select>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.created_at}</span>
							<input type="text" className="form-control"  name="createdAt"  defaultValue={moment(state.createdAt?.toMillis()).format('DD MMMM YYYY HH:mm')} disabled />
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.admin}</span>
							<select className="form-control" name="admin" value={state.admin} onChange={onChange}>
								<option value={true}>Yes</option>
								<option value={false}>No</option>
							</select>
						</div>
					</div>



				</div>

			</div>

			<div className="col-12 text-end">
				<button className="btn btn-success" disabled={user.loading || !state.name.length} onClick={onSubmit}>
					{user.loading ? <i className="fas fa-spin fa-spinner-third" /> : lang.save}
				</button>
			</div>

		</>
	)
}
 