import React from 'react'
import {Switch, Route} from 'react-router-dom'

import List from './List'
import View from './Preview'

export default function Reports(props) {

	return(
		<>
			<Switch>
				<Route exact path={['/my_reports', '/my_reports/list']} component={List} />
				<Route path={'/my_reports/:rid?'} component={View} />
			</Switch>
		</>
		
	)
}