import React from 'react'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

export default function List(props) {


	const {data: lang} 	= useSelector(state => state.language)
	const projects			= useSelector(state => state.projects)

	return(
		<>

			<ul className="nav nav-tabs">
				<li className="nav-item">
					<span className="nav-link active text-capitalize fw-bold">{lang.projects}</span>
				</li>
				
				<div className="separator" />

				<li>
					<Link to="/projects/create" className="btn btn-sm btn-success">
						{lang.add_project}
					</Link>
				</li>
					
			</ul>

			<div className="box mb-3">

				<div className="list-group-flush">

					{projects.list?.map(category => {

						return(
							<div className="list-group-item align-items-center pointer" key={category.objectId}>
								
								<div className="row">

									<div className="col-9 col-sm-6 col-md-6 mb-2 mb-md-0 d-grid align-items-center">
										<Link to={`/projects/${category.objectId}`} className="text-primary"><b>{category.name}</b></Link>
									
									</div>

									<div className="col-3 order-1 order-md-6 col-sm-6 col-md-6 mb-2 mb-md-0 d-grid align-items-center justify-content-end flex-grow-0">
										<small>{moment(category.createdAt.toMillis()).format('DD MMMM YYYY')}</small>
									</div>

								</div>

							</div>
						)
					})}

					{(!projects.list.length) &&
						<div className="list-group-item text-danger">{lang.no_projects}</div>
					}

				</div>

			</div>

		</>	
	)
}