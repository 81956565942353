export const VERSION = '1.0.0'
export const TITLE = 'Time Keeper'
export const DOMAIN = 'emp.grmechanical.co.uk'
export const API_URL = 'https://api.grmechanical.co.uk/'

export const FIREBASE_CONFIG = {
	apiKey: "AIzaSyDUS2rR7hDRsffcfyGUnuyBje-HrCRAxZY",
  authDomain: "timekeeper-f68eb.firebaseapp.com",
  projectId: "timekeeper-f68eb",
  storageBucket: "timekeeper-f68eb.appspot.com",
  messagingSenderId: "742785033964",
  appId: "1:742785033964:web:3c08be54008dd0ceab67c9",
  measurementId: "G-KQXLRQ6GW9"
 }


export const VALIDATOR = {
	name: {
		length: [ 2, 20 ],
		exp: 		RegExp(/^(?=.{2,30}$)[a-zA-Z\s]+$/)
	},
	email: {
		length: [5, 40],
		exp: 		RegExp(/^(?=.{5,40}$)[\w.-]+@[\w.-]+\.\w{2,4}$/)
	},
	password: {
		length: [5, 30],
		exp: 		RegExp(/^.{5,30}$/)
	},
	phone: {
		length: [10, 14],
		exp: 		RegExp(/^\+[1-9]\d{10,14}$/)
	},
	sms: {
		length: 6
	},
	message: {
		length: [5, 300]
	},
	age: {
		length: 2,
		value: 	[18, 75]
	}
}

export const ROUTES_ALLOWED = [ 
	'logout',
	'account',
	'notifications'
]

export const RESERVED_ROUTES = [ 
	'admin', 
	'privacy', 
	'terms', 
	'contact', 
	'faq' 
]

export const DEFAULT_LANGUAGE						= 'en'
export const LANGUAGE_UPDATED						= 'LANGUAGE_UPDATED'



export const USER_LOGGED_IN							= 'USER_LOGGED_IN'
export const USER_LOGGED_OUT						= 'USER_LOGGED_OUT'
export const USER_LOADING								= 'USER_LOADING'

export const USERS 											= 'users'
export const USERS_RESET								= 'USERS_RESET'
export const USERS_LOADED 							= 'USERS_LOADED'
export const USERS_LOADING 							= 'USERS_LOADING'
export const USERS_NEW_ITEM 						= 'USERS_NEW_ITEM'
export const USERS_LOADED_ALL 					= 'USERS_LOADED_ALL'
export const USERS_LOADED_MORE 					= 'USERS_LOADED_MORE'
export const USERS_UPDATED_ITEM 				= 'USERS_UPDATED_ITEM'
export const USERS_PER_REQUEST 					= 10

export const PROJECTS										= 'projects'
export const PROJECTS_LOADED						= 'PROJECTS_LOADED'
export const PROJECTS_NEW_ITEM					= 'PROJECTS_NEW_ITEM'
export const PROJECTS_UPDATE_ITEM				= 'PROJECTS_UPDATE_ITEM'

export const REPORTS										= 'reports'
export const REPORTS_LOADED 						= 'REPORTS_LOADED'
export const REPORTS_LOADING 						= 'REPORTS_LOADING'
export const REPORTS_CLEANUP						= 'REPORTS_CLEANUP'
export const REPORTS_NEW_ITEM 					= 'REPORTS_NEW_ITEM'
export const REPORTS_LOADED_ALL 				= 'REPORTS_LOADED_ALL'
export const REPORTS_LOADED_MORE 				= 'REPORTS_LOADED_MORE'
export const REPORTS_UPDATED_ITEM				= 'REPORTS_UPDATED_ITEM'
export const REPORTS_PER_REQUEST 				= 10