import {
	REPORTS_LOADED,
  REPORTS_LOADING,
	REPORTS_CLEANUP,
	REPORTS_NEW_ITEM,
	REPORTS_LOADED_ALL,
	REPORTS_LOADED_MORE,
	REPORTS_UPDATED_ITEM
} from '../constants'

const initialState = {
	loading: false,
  list: []
}

export default function Reports(state = initialState, action) {

  switch (action.type) {
    case REPORTS_LOADED:
      return {
				...state,
        loading: false,
        list: action.data || []
     }
    case REPORTS_LOADING:
      return {
        ...state,
        loading: true
			}
		case REPORTS_LOADED_MORE:
			return {
				...state,
				loading: false,
				list: [
					...state.list,
					...action.data
				]
			}
		case REPORTS_LOADED_ALL:
			return {
				...state,
				loading: false,
				noMoreToLoad: true
			}
    case REPORTS_NEW_ITEM:
      return {
        ...state,
				list: [action.data, ...state.list]
			}
		case REPORTS_UPDATED_ITEM:
			return {
				...state,
				list: state.list.map(report => {
					if (report.objectId === action.data.objectId)
						return {...report,  ...action.data}

					return report					
				})
			}
		case REPORTS_CLEANUP:
			return initialState	
    default:
      return state
	}

}