import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {updateProject} from '../../../actions/user'

export default function Edit(props) {

	const dispatch 	= useDispatch()
	const history 	= useHistory()
	const {cid} 		= useParams()

	const user					= useSelector(state => state.user)
	const {data: lang} 	= useSelector(state => state.language)
	const projects 			= useSelector(state => state.projects)

	const [state, changeState] = useState({
		name:				'',
	})

	const setState = data => changeState(prevState => ({...prevState, ...data}))
	
	const onChange = e => setState({[e.target.name]: e.target.value}) 

	const onSubmit = () => dispatch(updateProject(state))

	useEffect(() => {
		const category			= projects.list.find(item => item.objectId === cid) || {}
		setState({...category})
	},[cid, projects.list])

	return(
		<>
			<div className="columns" style={{marginBottom: 10}}>
				<i className="fas fa-chevron-left" onClick={history.goBack} style={{cursor: 'pointer', padding: 10, marginRight: 5}} />
				<h4 style={{margin: 0}}>{lang.edit_project}</h4>
			</div>

			<div className="box p-3 mb-3">

				<div className="row">

					<div className="col-12 mb-3">
						<div className="input-group input-group">
							<span className="input-group-text">{lang.name}</span>
							<input type="name" className="form-control"  name="name" value={state.name} onChange={onChange} />
						</div>
					</div>

				</div>

			</div>

			<div className="col-12 text-end">
				<button className="btn btn-success" disabled={user.loading || !state.name.length} onClick={onSubmit}>
					{user.loading ? <i className="fas fa-spin fa-spinner-third" /> : lang.save}
				</button>
			</div>

		</>
	)
}