import React from 'react'
import {useSelector} from 'react-redux'


import {
	useParams, 
	useHistory, 
	NavLink,
	Switch, 
	Route,
	Link
} from 'react-router-dom'


import Info from './Info'
import Reports from './Reports'

export default function View(props) {

	const history 	= useHistory()
	const {uid}		= useParams()

	const {data: lang} 	= useSelector(state => state.language)
	

	return(
		<>

			<div className="columns" style={{marginBottom: 10}}>
				<i className="fas fa-chevron-left" onClick={()=>history.push('/employees')} style={{cursor: 'pointer', padding: 10, marginRight: 5}} />
				<h4 style={{margin: 0}}>{lang.edit_user}</h4>
			</div>

			<ul className="nav nav-tabs">
				<li className="nav-item">
					<NavLink exact to={`/employees/${uid}`} className="nav-link text-capitalize fw-bold">{lang.info}</NavLink>
				</li>
				<li className="nav-item">
					<NavLink to={`/employees/${uid}/reports`} className="nav-link text-capitalize fw-bold">{lang.reports}</NavLink>
				</li>

				<div className="separator"/>
				{history.location.pathname.includes('reports') &&
					<li>
						<Link to="reports/new" className="btn btn-sm btn-success">
							{lang.new_report}
						</Link>
					</li>
				}
			</ul>

			<Switch>
				<Route exact path={`/employees/:uid?`} component={Info} />
				<Route path={`/employees/:uid?/reports`} component={Reports} />
			</Switch>

		</>
	)
}
 