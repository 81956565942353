import React,{useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'
import {Link} from 'react-router-dom'

import {
	checkReport,
	createReport 
} from '../../../actions/user'

export default function New(props) {

	const dispatch = useDispatch()

	const {data: lang} = useSelector(state => state.language)

	const [weeks, setWeeks] = useState([])
	const [loading, setLoading] = useState(false)
	const [exist, setExist] = useState({})
	const [state, changeState] = useState({
		week			: '',
		weekCount	: 0,
		monday		: 0,
		tuesday		: 0,
		wednesday	: 0,
		thursday	: 0,
		friday		: 0,
		saturday	: 0,
		sunday		: 0
	})

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onGenerateWeeks = () => {
 

		let week = moment().startOf('isoWeek')
		let weeks = []

		let prevWeek = week.clone().add(-1, 'w')

		weeks.push(`${prevWeek.format('DD/MM/YYYY')} - ${prevWeek.clone().add(6, 'd').format('DD/MM/YYYY')}`)
		weeks.push(`${week.format('DD/MM/YYYY')} - ${week.clone().add(6, 'd').format('DD/MM/YYYY')}`)
		

		setWeeks(weeks)

	}

	const onChange = e => setState({[e.target.name]: e.target.value})

	useEffect(() => {
		onGenerateWeeks()
	}, [])

	useEffect(() => {

		if (!state.week) {
			setLoading(false)
			setExist({})
			return false
		}
			
		const check = async () => {

			setLoading(true)
			let response =	await dispatch(checkReport(state.week))
			setLoading(false)
			setExist(response)

		}

		check()

	}, [dispatch, state.week])

	const onSubmit = () => {
		dispatch(createReport({...state}))
	}

	return(
		<>
			<div className="box noTop p-3">
				<div className="input-group input-group">
					<span className="input-group-text">{lang.week}</span>
					<select type="number" className="form-control"  name="week" value={state.week} onChange={onChange}>
						<option value="">{lang.select_week}</option>
						{weeks.map(week => <option key={week} value={week}>{week}</option>)}
					</select>	
				</div>
			</div>

			<div className="box mt-3">

				{!loading && state.week && !exist.objectId &&
					<div className="row mt-2 p-3">

						{/* Monday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.monday}</span>
								<input type="number" className="form-control"  name="monday" value={state.monday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						{/* Tuesday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.tuesday}</span>
								<input type="number" className="form-control"  name="tuesday" value={state.tuesday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>	

						{/* Wednesday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.wednesday}</span>
								<input type="number" className="form-control"  name="wednesday" value={state.wednesday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>	

						{/* Thursday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.thursday}</span>
								<input type="number" className="form-control"  name="thursday" value={state.thursday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						{/* Friday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.friday}</span>
								<input type="number" className="form-control"  name="friday" value={state.friday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>	

						{/* Saturday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.saturday}</span>
								<input type="number" className="form-control"  name="saturday" value={state.saturday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						{/* Sunday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.sunday}</span>
								<input type="number" className="form-control"  name="sunday" value={state.sunday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						<div className="col-12 col-md-12 mb-3 text-end">
							<button className="btn btn-success" onClick={onSubmit}>
									SUBMIT
							</button>
						</div>

					</div>
				}

				{!state.week && !exist.objectId &&
					<div className="text-danger p-3">{lang.week_not_selected}</div>
				}

				{state.week && exist.objectId &&
					<div className="text-danger p-3">{lang.report_already_exist} <Link to={`/reports/${exist.objectId}`}>View report</Link></div>
				}

				{loading &&
					<div className="text-center p-3">
					 	<span className="sr-only">Loading...</span>
					</div>
				}



			</div>

		</>
	)

}