import React,{useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

import {
	updateUserReport
} from '../../../../../actions/user'

export default function New(props) {

	const dispatch = useDispatch()
	const {rid} 	 = useParams()

	const initialState = {
		week			: '',
		weekCount	: 0,
		monday		: 0,
		tuesday		: 0,
		wednesday	: 0,
		thursday	: 0,
		friday		: 0,
		saturday	: 0,
		sunday		: 0
	}

	const user					= useSelector(state => state.user)
	const reports				= useSelector(state => state.reports)
	const {data: lang}  = useSelector(state => state.language)

	const [state, changeState] 	= useState(initialState)
	

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onChange = e => setState({[e.target.name]: e.target.value})



	useEffect(() => {

			let report = reports.list.find(item => item.objectId === rid) || {}

			return	setState({
			week			: report.period,
			monday		: report.monday,
			tuesday		: report.tuesday,
			wednesday	: report.wednesday,
			thursday	: report.thursday,
			friday		: report.friday,
			saturday	: report.saturday,
			sunday		: report.sunday,
			objectId	: report.objectId
		})
		
	}, [reports.list, rid])

	const onSubmit = async () =>  await dispatch(updateUserReport({...state}))

	return(
		<>

			<div className="box p-3">

				<h5><b>{state.week}</b></h5>

				<hr />

				{state.week &&
					<div className="row mt-3">

						{/* Monday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.monday}</span>
								<input type="number" className="form-control"  name="monday" value={state.monday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						{/* Tuesday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.tuesday}</span>
								<input type="number" className="form-control"  name="tuesday" value={state.tuesday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>	

						{/* Wednesday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.wednesday}</span>
								<input type="number" className="form-control"  name="wednesday" value={state.wednesday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>	

						{/* Thursday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.thursday}</span>
								<input type="number" className="form-control"  name="thursday" value={state.thursday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						{/* Friday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.friday}</span>
								<input type="number" className="form-control"  name="friday" value={state.friday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>	

						{/* Saturday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.saturday}</span>
								<input type="number" className="form-control"  name="saturday" value={state.saturday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						{/* Sunday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.sunday}</span>
								<input type="number" className="form-control"  name="sunday" value={state.sunday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						<div className="col-12 mb-3 text-end">
							<button className="btn btn-success" onClick={onSubmit} disabled={user.loading}>
								{user.loading ? <i className="fas fa-spin fa-spinner-third" /> : state.objectId ? lang.save : lang.submit}	
							</button>
						</div>

					</div>
				}

			</div>

		</>
	)

}