import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {login} from '../../../actions/user'


export default function Login() {

	const history = useHistory()
	const dispatch = useDispatch()
	
	const passwordInput = useRef()
	const user 					= useSelector(state => state.user)
	const {data: lang} 	= useSelector(state => state.language)

	const [state, changeState] = useState({
		email: '',
		password: ''
	})

	const setState = data => changeState(prevState => ({...prevState, ...data})) 

	useEffect(() => {
		if (user.objectId)
			history.replace('/')
 }, [history, user.objectId])
	
	const onSubmit = () => dispatch(login(state.email, state.password))

	const onInputChange = e => setState({[e.target.name]: e.target.value})

	const onKeyDown = e => {

		if (e.which !== 13) 
			return false

		if (!state.password.trim().length) 
			return passwordInput.current.focus()

		return onSubmit()
	}

	return(
		<div className="container-fluid">
			<div className="connect">
								
				<input
					type="text"
					name="email"
					value={state.email}
					placeholder="Email"
					className="form-control mb-2"
					maxLength={40}
					onKeyDown={onKeyDown}
					onChange={onInputChange}
				/>

				<input
					type="password"
					name="password"
					ref={passwordInput}
					value={state.password}
					placeholder="Password"
					className="form-control mb-4"
					maxLength={30}
					onKeyDown={onKeyDown}
					onChange={onInputChange}
				/>

				<div className="d-grid">
					<button className="btn btn-success" onClick={onSubmit} disabled={user.loading || !state.email.length || !state.password.length}>
						{user.loading ? <i className="fas fa-spin fa-spinner-third" /> : lang.connect}
					</button>
				</div>

			</div>
		</div>
	)
}