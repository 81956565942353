import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {fetchUsers} from '../../../actions/user'

export default function List(props) {

	const dispatch = useDispatch()

	const [keyword, setKeyword] = useState('')
	const [type, setType] = useState('includes')
	const [search, setSearch] = useState(false)

	const users 				= useSelector(state => state.users)
	const projects 			= useSelector(state => state.projects)
	const {data: lang} 	= useSelector(state => state.language)

	let week = moment().startOf('isoWeek')
	let pWeek = week.clone().add(-1, 'w')
	let currentWeek = `${week.format('DD MMMM YYYY')} - ${week.clone().add(6, 'd').format('DD MMMM YYYY')}`
	let prevWeek = `${pWeek.format('DD MMMM YYYY')} - ${pWeek.clone().add(6, 'd').format('DD MMMM YYYY')}`

	const onSearch = () => {
		setSearch(true)
		dispatch(fetchUsers(true, type, keyword))		
	}

	const onClear = () => {
		setSearch(false)
		setKeyword('')
		dispatch(fetchUsers(true))		
	}

	const onToggleType = e => {
		setKeyword('')
		setType(e.target.value)
	}

	useEffect(()=> {
		dispatch(fetchUsers(true))
	},[dispatch])

	return(
		<>

			<div className="input-group mb-3">

				<select style={{width: 'auto', flex: 'unset'}} className="form-select" value={type} onChange={onToggleType}>
					<option value="includes">{lang.contains}</option>
					<option value="lastReport">{lang.status}</option>
					<option value="project">{lang.project}</option>
				</select>

				{type === 'includes' &&
					<input type="text" className="form-control" placeholder="Name, email, phone" value={keyword} onChange={e => setKeyword(e.target.value)} />
				}

				{type === 'lastReport' &&
					<select className="form-select" value={keyword} onChange={e => setKeyword(e.target.value)}>
						<option value="">{lang.select_status}</option>
						<option value='submitted'>{lang.submitted}</option>
						<option value='not_submitted'>{lang.not_submitted}</option>
					</select>
				}

				{type === 'project' &&
					<select className="form-select" value={keyword} disabled={!projects.list.length} onChange={e => setKeyword(e.target.value)}>
						<option value="">{lang.not_assigned}</option>
						{projects.list.map(item => <option key={item.objectId} value={item.objectId}>{item.name}</option>)}
					</select>
				}

				<button className="btn btn-primary" onClick={onSearch} disabled={type !== 'project' && !keyword?.length}>{lang.search}</button>
				{search && <button className="btn btn-danger" onClick={onClear}>{lang.clear}</button> }

			</div>
			
			<ul className="nav nav-tabs">
				<li className="nav-item">
					<span className="nav-link active text-capitalize fw-bold">{lang.employees}</span>
				</li>

				<div className="separator"/>

				<li>
					<Link to="/employees/create" className="btn btn-sm btn-success">
						{lang.add_user}
					</Link>
				</li>
					
			</ul>

			<div className="box mb-3">

				<div className="list-group-flush">

					{users.list?.map(user => {

						let project 		= projects.list.find(item => item.objectId === user.project) || {}
						let isReported 	= user.lastReport === currentWeek || user.lastReport === prevWeek

						return(
							<div className="list-group-item align-items-center" key={user.objectId}>
								
								<div className="row">

									<div className="col-9 col-sm-6 col-md-6 mb-2 mb-md-0 d-grid align-items-center">
										<Link to={`/employees/${user.objectId}`}><b>{user.name}</b></Link>
										<div className="text-muted"><small>{user.email}</small></div>
									</div>

									<div className="col-12 order-2 order-md-1 col-md-3 mb-2 mb-md-0 d-grid align-items-center">
										
										{user.project && <small className={`text-${isReported ? 'success': 'danger'}`} style={{textTransform: 'uppercase'}}>
											<b>{isReported ? lang.submitted: lang.not_submitted}</b>
										</small>}
										<small>{user.lastReport}</small>
									</div>

									<div className="col-3 order-1 order-md-3 col-sm-6 col-md-3 mb-2 mb-md-0 d-grid align-items-center justify-content-end text-end flex-grow-0">
										<small className={!project.name ? 'text-danger': ''}>{project.name || lang.not_assigned}</small>
									</div>

								</div>

							</div>
						)
					})}

					{(!users.list.length && users.noMoreToLoad) &&
						<div className="list-group-item text-danger">{lang.no_employee}</div>
					}
		
					{!users.noMoreToLoad &&
						<div className="list-group-item text-center">
							<button className="btn btn-sm btn-primary" onClick={() => dispatch(fetchUsers(false, type, keyword))} disabled={users.loading}>
								{users.loading ? <i className="fas fa-spin fa-spinner-third" /> : lang.load_more}
							</button>
						</div>
					}

				</div>

			</div>

		</>
	)
}