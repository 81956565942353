import {
	USER_LOADING,
	USER_LOGGED_OUT,
	USER_LOGGED_IN
	} from '../constants'
	
	const initialState = {
		guest: null,
		loading: true,
	}
	
	export default function User(state = initialState, action) {
		switch (action.type) {
			case USER_LOGGED_IN:
				return {
					...state,
					guest: false,
					loading: false,
					...action.data
				}
			case USER_LOGGED_OUT:
				return {
					...initialState,
					guest: true,
					loading: false,
				}
			case USER_LOADING: 
				return {
					...state,
					loading: action.data
				}
			default:
				return state
		}
	}