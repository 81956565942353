import React,{useRef, useState} from 'react'
import moment from 'moment'
import {useSelector, useDispatch} from 'react-redux'
import {fetchReports} from '../../../actions/user'
import ReactExport from "react-export-excel";
import Preview from '../Preview'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function List(props) {

	const dispatch = useDispatch()

	const {data: lang} 	= useSelector(state => state.language)
	const projects		= useSelector(state => state.projects)
	const TODAY = moment().format('YYYY-MM-DD')

	const endRef = useRef(null)
	const [range, setRange] = useState({
		startDate: moment().format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD')
	})

	const [loading, setLoading] = useState(false)
	const [totals, setTotals] = useState([])
	const [exportData, setExportData] = useState([])
	const [users, setUsers] = useState([])
	const [reports, setReports] = useState([])
	const [preview, setPreview] = useState([])
	const [previewUser, setPreviewUser] = useState('')
	const [category, setCategory] = useState('all')

	const onInputChange = e => {

		setRange({...range, [e.target.name]: e.target.value})

		if (e.target.name === 'startDate')
			endRef.current.focus()

	} 

	const onFetch = async () => {
		
		setLoading(true)

		let dates = {
			startDate: moment(range.startDate).startOf('day').toDate(),
			endDate: moment(range.endDate).endOf('day').toDate()
		}

		let response = await dispatch(fetchReports(category, dates))
		setTotals(response.totals)
		setUsers(response.users)
		setReports(response.reports)
		setExportData(response.exportData)
		setLoading(false)

	}

	const onViewReport = (category, owner) => {
		
		let preview 	= reports.filter(item => (item.category === category && item.owner === owner))
		let user		 	= users.find(item => item.objectId === owner) || {}

		setPreview(preview)
		setPreviewUser(user.name)

	}

	if (preview.length)
		return<Preview user={previewUser} items={preview} onBack={() => setPreview([])} />
	


	return(
		<div>

			<div className="input-group mb-3">
				<select style={{width: 'auto'}} className="form-select" value={category} onChange={e => setCategory(e.target.value)}>
					<option value="all">All</option>
					{projects.list.map(category => <option key={category.objectId} value={category.objectId}>{category.name}</option>)}
				</select>
				<input type="date" className="form-control" value={range.startDate} max={TODAY} name="startDate" onChange={onInputChange} />
				<input type="date" className="form-control" ref={endRef} value={range.endDate} min={range.startDate}  name="endDate" onChange={onInputChange} />
				<button className="btn btn-success" onClick={onFetch} disabled={loading}>
					{loading ? <i className="fas fa-spin fa-spinner-third" /> : lang.fetch}
				</button>
			</div>
		
			{Object.keys(totals).map(categoryKey => {

				let category = projects.list.find(item => item.objectId === categoryKey) || {}
				
			
				return (
					<React.Fragment key={categoryKey}>

					

						<ul className="nav nav-tabs">
							<li className="nav-item">
								<span className="nav-link active text-capitalize fw-bold">{category.name}</span>
							</li>	
						</ul>

						<div className="box mb-3">

							<div className="list-group-flush">

								{Object.keys(totals[categoryKey]).map(userKey => {

										let user = users.find(item => item.objectId === userKey) || {}
										let report = totals[categoryKey][userKey]
										let totalHours = report.monday + report.tuesday + report.wednesday + report.thursday + report.friday + report.saturday + report.sunday

										return(
											<div className="list-group-item align-items-center" key={userKey} onClick={()=> onViewReport(categoryKey, userKey)}>
												<div className="row">

													<div className="col-9 col-sm-6 col-md-6 mb-2 mb-md-0 d-grid align-items-center">
														<div className="text-primary"><b>{user.name}</b></div>
														<div className="text-muted">{user.phone}</div>
													</div>

													<div className="col-12 order-2 order-md-1 col-md-3 mb-2 mb-md-0 d-flex align-items-center text-center">
														<div className="text-muted">{totalHours} {lang.hours}</div>
													</div>

													<div className="col-3 order-1 order-md-3 col-sm-6 col-md-3 mb-2 mb-md-0 d-grid align-items-center justify-content-end flex-grow-0">
														<small> Weeks: <b>{report.count}</b></small>
													</div>

												</div>
											</div>
										)
								})}

							</div>
							
						</div>

					</React.Fragment>
				)	
			})}

			{!exportData.length ?
				<div className="list-group-item text-danger">{lang.no_reports}</div>
				: null
			}


			{exportData.length ? 
			<div className="col-12 text-center">
				<ExcelFile filename={`Export ${moment().format('DD MMMM YYYY')}`} element={
					<button className="btn btn-success"> Export XLS </button>
				}>
					{exportData.map((report, index) => {
						return <ExcelSheet  key={index} data={report.data} name={report.project}>
							<ExcelColumn label="Name" value="name"/>
							<ExcelColumn label="Project" value="project"/>
							<ExcelColumn label="Total" value="total"/>
							<ExcelColumn label="Monday" value="monday"/>
							<ExcelColumn label="Tuesday" value="tuesday"/>
							<ExcelColumn label="Wednesday" value="wednesday"/>
							<ExcelColumn label="Thursday" value="thursday"/>
							<ExcelColumn label="Friday" value="friday"/>
							<ExcelColumn label="Saturday" value="saturday"/>
							<ExcelColumn label="Sunday" value="sunday"/>
							<ExcelColumn label="Week" value="period"/>
						</ExcelSheet>
					})}
				</ExcelFile>
				</div>
			: null}
			
		</div>	
	)
}