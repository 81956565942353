import React, {useEffect} from 'react'
import {Switch, Route, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {fetchUserReports, cleanupUserReports} from '../../../../actions/user'

import List from './List'
import Edit from './Edit'
import New from './New'

export default function Reports(props) {

	const dispatch 	= useDispatch()
	const {uid} 		= useParams()

	useEffect(() => {
		dispatch(fetchUserReports(uid))
		return () => dispatch(cleanupUserReports())
	},[uid, dispatch])

	return(
		<>
			<Switch>
				<Route exact path={'/employee/:uid/reports'} component={List} />
				<Route exact path='/employee/:uid/reports/new' component={New} />
				<Route path='/employee/:uid/reports/:rid?' component={Edit} /> 
			</Switch>
		</>
		
	)
}