import {
	PROJECTS_LOADED,
	PROJECTS_NEW_ITEM,
	PROJECTS_UPDATE_ITEM
} from '../constants'

const initialState = {
	loading: true,
	list: []
}

export default function Projects(state = initialState, action) {
	switch (action.type) {
		case PROJECTS_LOADED:
			return {
				loading: false,
				list: action.data
			}
		case PROJECTS_NEW_ITEM:
			return {
				...state,
				list: [...state.list, action.data]
			}	
		case PROJECTS_UPDATE_ITEM:
			return {
				...state,
				list: state.list.map(category => {
					if (category.objectId === action.data.objectId)
						return {...category, ...action.data}

					return category					
				})
			}	
		default:
			return state
	}
}