import lang from '../lang'

import {
	DEFAULT_LANGUAGE,
	LANGUAGE_UPDATED
} from '../constants'

const initialState = {
	descriptors: {},
	list: Object.keys(lang),
	data: lang[DEFAULT_LANGUAGE]
}

initialState.list.forEach(item => initialState.descriptors[item] = lang[item].descriptor)

export default function Language(state = initialState, action) {
	switch (action.type) {
		case LANGUAGE_UPDATED: 
			return {
				...state,
				data: lang[action.data]
			}
		default:
			return state
	}
}