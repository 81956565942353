/************************************************
* IMPORTS
***********************************************/

import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunk from 'redux-thunk'
 

/************************************************
* REDUCERS
***********************************************/
import projects from './projects'
import language from './language'
import reports from './reports'
import users from './users'
import user from './user'

const reducres = {
	projects,
	language,
	reports,
	users,
	user
}

/************************************************
* STORE
***********************************************/

export default createStore(
	combineReducers(reducres),
	applyMiddleware(thunk)
)