
/************************************************
* IMPORTS
************************************************/

import {
	db,
  auth
} from '../utils/firebase'
import moment from 'moment'


import {
	USERS,
	USERS_RESET,
	USERS_LOADED,
	USERS_LOADED_ALL,
	USERS_LOADED_MORE,
	USERS_LOADING,
	USERS_NEW_ITEM,
	USERS_PER_REQUEST,
	USER_LOADING,
	USER_LOGGED_IN,
	USER_LOGGED_OUT,

	REPORTS,
	REPORTS_LOADED,
	REPORTS_CLEANUP,
	REPORTS_LOADED_ALL,
	REPORTS_LOADED_MORE,
	REPORTS_LOADING,
	REPORTS_NEW_ITEM,
	REPORTS_PER_REQUEST,
	REPORTS_UPDATED_ITEM,

	PROJECTS,
	PROJECTS_LOADED,
	PROJECTS_NEW_ITEM,
	PROJECTS_UPDATE_ITEM,

	VALIDATOR,
	USERS_UPDATED_ITEM

} from '../constants'

import { 
	prefixSuccessor,
	showMessage,
	history,
	request,
	toTitleCase
} from '../utils'


/************************************************
* FIREBASE STORE INIT / UNSUBSCRIBE
************************************************/

const unsubscribe = {}

const store = {
	users: db.collection(USERS),
	reports: db.collection(REPORTS),
	projects: db.collection(PROJECTS)
}


/************************************************
* UNSUBSCRIBE
************************************************/
export const cleanupUserReports = () => dispatch => {

	if (unsubscribe.userReports)
		unsubscribe.userReports()

	dispatch({type: REPORTS_CLEANUP})	

}


/************************************************
* LOGIN FAILED
* @param {string} message Error message
* @returns {string}
************************************************/

 function loginFailed(message) {
	showMessage(message)
	return { 
		type: USER_LOADING, 
		data: false 
	}
}


/************************************************
* AUTO LOGIN STATUS DETECTION
************************************************/

export const detectLoginStatus = () => dispatch => {
	auth.onAuthStateChanged(async account => {

		let isAdmin  = false

		dispatch({
			type: USER_LOADING,
			data: true
		})

		if (!account)
			return dispatch({type: USER_LOGGED_OUT})

			

		unsubscribe.user = store.users
			.doc(account.uid)
			.onSnapshot(async snapshot => {

				if (!snapshot.exists)
					return dispatch(logout())

				isAdmin = await auth.currentUser.getIdTokenResult().then(result => !!result.claims.admin)
				
				console.log(isAdmin)
				let profile = snapshot.data()
				profile.admin = isAdmin

				if (isAdmin) {
			
				} else {
					dispatch(fetchMyReports())
				}
	
				dispatch(fetchProjects())

				dispatch({ 
					type: USER_LOGGED_IN, 
					data: profile
				})
				
			}, () => {})

  })
	
}


/************************************************
* EMAIL/PASSWORD LOGIN
* @param {string} email Email address
* @param {string} password Account password
* @returns {promise}
************************************************/

export const login = (email, password) => async dispatch => {

	email = email.trim()
	password = password.trim()
	
	if (!email.length || !password.length)
		return  dispatch(loginFailed('Please fill in both fields') )

	dispatch({ 
		type: USER_LOADING, 
		data: true
	})

	let result = await auth.signInWithEmailAndPassword(email, password).catch(err => err)

	if (result instanceof Error)
		return dispatch(loginFailed(result.code))
	
	history.replace('/')

	return true

}


/************************************************
* LOGOUT METHOD
************************************************/

export const logout = () => () => {

	// redirect to logout page
	history.replace('/logout')

	// unbind listeners
	Object.keys(unsubscribe).forEach( prop => {
		unsubscribe[prop]() 
		delete unsubscribe[prop]
	})

	auth.signOut()

}


/************************************************
* CREATE USER
* @param {string} email
* @param {string} password
* @param {string} name
* @param {string} phone
* @param {string} project
************************************************/

export const createUser = (email, password, name, phone, project) => async (dispatch, getState) => {

	// get user id
	const {uid} = auth.currentUser || {}

	// get state
	const {language: {data: lang}, user} = getState()

	// check if loading
	if (user.loading || !uid)
		return false
		
	// trim fields
	email 		= email.trim()
	password 	= password.trim()
	name 			= name.trim()
	phone 		= phone.trim()
	project 	= project.trim()

	// validate fields
	if (!email.length || !password.length || !name.length)
		return showMessage(lang.all_fields_required)

	// email validation
	if (!VALIDATOR.email.exp.test(email))
		return showMessage(lang.email_validation)

	// password validation
	if (!VALIDATOR.password.exp.test(password))
		return showMessage(lang.password_validation)

	// name validation
	if (!VALIDATOR.name.exp.test(name))
		return showMessage(lang.name_validation)


	let payload = {
		email,
		password,
		name,
		phone,
		project
	}

	// start loading
	dispatch({
		type: USER_LOADING,
		data: true
	})

	// triger the request
	let response = await request('users/create', payload)
	
	// start loading
	dispatch({
		type: USER_LOADING,
		data: false
	})

	// go back
	if (response.type === 'success')
		history.goBack()

	// dispaly message
	return showMessage(lang[response.message] || response.message, response.type)


}


/************************************************
* CREATE project
* @param {string} name
************************************************/

export const createProject= name => async (dispatch, getState) => {

	// get user id
	const {uid} = auth.currentUser || {}

	// get state
	const {language: {data: lang}, user} = getState()

	// check if loading
	if (user.loading || !uid)
		return false
		
	// trim fields
	name 			= name.trim()

	// name validation
	if (!name.length)
		return showMessage(lang.name_validation)


	let payload = {
		name
	}

	// start loading
	dispatch({
		type: USER_LOADING,
		data: true
	})

	// triger the request
	let response = await request('projects/create', payload)
	
	// start loading
	dispatch({
		type: USER_LOADING,
		data: false
	})

	// go back
	if (response.type === 'success')
		history.goBack()

	// dispaly message
	return showMessage(lang[response.message] || response.message, response.type)


}

/************************************************
* CREATE project
* @param {object} data
************************************************/

export const updateProject = data => async (dispatch, getState) => {

	// get user id
	const {uid} = auth.currentUser || {}

	// get state
	const {language: {data: lang}, user} = getState()

	// check if loading
	if (user.loading || !uid)
		return false
		
	// trim fields
	data.name 			= data.name.trim()

	if (!data.objectId)
		return false

	// name validation
	if (!data.name.length)
		return showMessage(lang.name_validation)


	let payload = {
		name: data.name,
		objectId: data.objectId
	}

	// start loading
	dispatch({
		type: USER_LOADING,
		data: true
	})

	// triger the request
	let response = await request('projects/update', payload)
	
	// start loading
	dispatch({
		type: USER_LOADING,
		data: false
	})

	// go back
	if (response.type === 'success') {

		dispatch({
			type: PROJECTS_UPDATE_ITEM, 
			data: payload
		})

		history.goBack()

	}
	

	// dispaly message
	return showMessage(lang[response.message] || response.message, response.type)


}


/************************************************
* UPDATE USER
* @param {object} data
************************************************/

export const updateUser = data => async (dispatch, getState) => {

	// get user id
	const {uid} = auth.currentUser || {}

	// get state
	const {language: {data: lang}, user} = getState()

	// check if loading
	if (user.loading || !uid)
		return false
		
	data.name 			= data.name.trim()
	data.phone 			= data.phone.trim()
	data.project 	= data.project.trim()

	// validate fields
	if (!data.objectId.length || !data.name.length)
		return showMessage(lang.all_fields_required)

	// name validation
	if (!VALIDATOR.name.exp.test(data.name))
		return showMessage(lang.name_validation)

	// phone validation
	if (data.phone && !VALIDATOR.phone.exp.test(data.phone))
		return showMessage(lang.phone_validation)	

	let payload = {...data}

	delete payload.createdAt
	delete payload.email
	delete payload.lastReport

	// start loading
	dispatch({
		type: USER_LOADING,
		data: true
	})

	// triger the request
	let response = await request('users/update', payload)
	
	// start loading
	dispatch({
		type: USER_LOADING,
		data: false
	})

	// update user 
	if (response.type === 'success')
		dispatch({
			type: USERS_UPDATED_ITEM,
			data: payload
		})

	// dispaly message
	return showMessage(lang[response.message] || response.message, response.type)


}

/************************************************
* FETCH USERS
* @param {bool} reset
* @param {string} type
* @param {string} criteria
************************************************/

export const fetchUsers = (reset = false, type = null, criteria = null) => (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid) 
		return false

	if (reset)
		dispatch({type: USERS_RESET})		

	const {users} = getState()
	
	if (users.loading || users.noMoreToLoad)
		return false

	let query = store.users

	if (type) {

		if (type === 'includes') {

			if (VALIDATOR.email.exp.test(criteria))
				query = query.where('email', '==', criteria)

			else if (VALIDATOR.phone.exp.test(criteria))
				query = query.where('phone', '==', criteria)	
			
			else  {
				criteria = toTitleCase(criteria)
				query = query.where('name', '>=', criteria)
				.where('name', '<', prefixSuccessor(criteria))
				.orderBy('name', 'asc')
			}
				
		}
		else if (type === 'lastReport') {
		
			let week = moment().startOf('isoWeek')
			let pWeek = week.clone().add(-1, 'w')
			let prevWeek = `${pWeek.format('DD MMMM YYYY')} - ${pWeek.clone().add(6, 'd').format('DD MMMM YYYY')}`

			if (criteria === "submitted")
				query = query.where('lastReport','==', prevWeek)
			else 
				query = query.where('lastReport','!=', prevWeek)
				.orderBy('lastReport', 'asc')
			
		}
		else
			query = query.where(type, '==', criteria)

	}

	
	query = query.orderBy('createdAt', 'desc')
	
	dispatch({type: USERS_LOADING})

	if (users.list.length)
		return query
			.startAfter(users.list[users.list.length - 1].createdAt)
			.limit(USERS_PER_REQUEST)
			.get()
			.then(snapshot => {
				
				if (snapshot.empty)
					return dispatch({type: USERS_LOADED_ALL})

				let items = snapshot.docs.map(doc => doc.data())

				dispatch({
					type: USERS_LOADED_MORE,
					data: items
				})
				
				if (items.length < USERS_PER_REQUEST)
					dispatch({type: USERS_LOADED_ALL})

			})
	else
		return query
			.limit(USERS_PER_REQUEST)
			.get()
			.then(snapshot => {

				if (!snapshot.empty)
					query = query.endBefore(snapshot.docs[0])

				if (unsubscribe.users)
				unsubscribe.users()
					
				unsubscribe.users = query.onSnapshot(snapshot => 
					snapshot.docChanges().forEach(change => {
						if (change.type === 'added')
							dispatch({
								type: USERS_NEW_ITEM,
								data: change.doc.data()
							})
					})
				)

				if (snapshot.empty)
				{
					dispatch({type: USERS_LOADED})
					dispatch({type: USERS_LOADED_ALL})
					return false
				}

				let items = snapshot.docs.map(doc => doc.data())

				dispatch({
					type: USERS_LOADED,
					data: items
				})

				if (items.length < USERS_PER_REQUEST)
					dispatch({type: USERS_LOADED_ALL})

			})
	

}


/************************************************
* FETCH PROJECTS
************************************************/

export const fetchProjects= (report = false) => (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid) 
		return false

	let query =store.projects
	.orderBy('createdAt', 'desc')

	query
	.get()
	.then(snapshot => {

		let items = snapshot.docs.map(item => item.data())

		if (!snapshot.empty)
			query = query.endBefore(snapshot.docs[0])

		if (unsubscribe.projects)
		unsubscribe.projects()
			
		unsubscribe.projects = query.onSnapshot(snapshot => 
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added')
					dispatch({
						type: PROJECTS_NEW_ITEM,
						data: change.doc.data({serverTimestamps: 'estimate'})
					})
			})
		)

		dispatch({
			type: PROJECTS_LOADED,
			data: items
		})

	})

}	

/************************************************
* FETCH REPORTS
************************************************/

export const fetchMyReports = (report = false) => (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid) 
		return false

	const {reports} = getState()
	
	if (reports.loading || reports.noMoreToLoad)
		return false

	let query = store.reports
	
	// if (report)
	// 	query = query.where('objectId', '==', report)

	query = query.where('owner', '==', uid)
	query = query.orderBy('week', 'desc')
	
	dispatch({type: REPORTS_LOADING})

	if (reports.list.length)
		return query
			.startAfter(reports.list[reports.list.length - 1].createdAt)
			.limit(REPORTS_PER_REQUEST)
			.get()
			.then(snapshot => {
				
				if (snapshot.empty)
					return dispatch({type: REPORTS_LOADED_ALL})

				let items = snapshot.docs.map(doc => doc.data())

				dispatch({
					type: REPORTS_LOADED_MORE,
					data: items
				})
				
				if (items.length < REPORTS_PER_REQUEST)
					dispatch({type: REPORTS_LOADED_ALL})

			})
	else
		return query
			.limit(REPORTS_PER_REQUEST)
			.get()
			.then(snapshot => {

				if (!snapshot.empty)
					query = query.endBefore(snapshot.docs[0])

				if (unsubscribe.reports)
				unsubscribe.reports()
					
				unsubscribe.reports = query.onSnapshot(snapshot => 
					snapshot.docChanges().forEach(change => {
						if (change.type === 'added')
							dispatch({
								type: REPORTS_NEW_ITEM,
								data: change.doc.data({serverTimestamps: 'estimate'})
							})
					})
				)

				if (snapshot.empty)
				{
					dispatch({type: REPORTS_LOADED})
					dispatch({type: REPORTS_LOADED_ALL})
					return false
				}

				let items = snapshot.docs.map(doc => doc.data())

				dispatch({
					type: REPORTS_LOADED,
					data: items
				})

				if (items.length < REPORTS_PER_REQUEST)
					dispatch({type: REPORTS_LOADED_ALL})

			})
	

}

/************************************************
* CHECK REPORTS EXIST
* @param {string} week 
* @returns {object}
************************************************/

export const checkReport = week  => (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid) 
		return false

	return store.reports
	.where('period', '==', week)
	.where('owner', '==', uid)
	.limit(1)
	.get()
	.then(snapshot => !snapshot.empty ? snapshot.docs[0].data()	: {})
	.catch(() => ({}))

}

/************************************************
* CREATE NEW REPORT
* @param {object} data 
************************************************/

export const createReport = data  => async (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid) 
		return false

	const {language:{data: lang}, user} = getState()

	let check = await store.reports
	.where('period', '==', data.week)
	.where('owner', '==', uid)
	.limit(1)
	.get()
	.then(snapshot => !snapshot.empty)

	if (!user.project)
		return showMessage(lang.not_in_a_project)

	if (check)
		return showMessage(lang.report_already_exist)

	dispatch({
		type: USER_LOADING,
		data: true
	})

	data.project = user.project

	// triger the request
	let response = await request('reports/create', data)


	dispatch({
		type: USER_LOADING,
		data: false
	})

	// dispaly message
	showMessage(lang[response.message] || response.message, response.type)

	return response.type
	
}


/************************************************
* UPDATE PERSONAL
* @param {object} data 
************************************************/

export const updateUserPersonal = data  => async (dispatch, getState) => {

	// get user id
	const {uid} = auth.currentUser || {}

	// get state
	const {language: {data: lang}, user} = getState()

	// check if loading
	if (user.loading || !uid)
		return false
		
	data.name 			= data.name.trim()
	data.phone 			= data.phone.trim()

	// validate fields
	if (!data.name.length)
		return showMessage(lang.all_fields_required)

	// name validation
	if (!VALIDATOR.name.exp.test(data.name))
		return showMessage(lang.name_validation)

	// phone validation
	if (data.phone && !VALIDATOR.phone.exp.test(data.phone))
		return showMessage(lang.phone_validation)

	// start loading
	dispatch({
		type: USER_LOADING,
		data: true
	})

	// triger the request
	let result = await store.users.doc(uid)
		.update(data)
		.catch(err => err)
	
	// start loading
	dispatch({
		type: USER_LOADING,
		data: false
	})

	if (result instanceof Error)
		return showMessage(result.code)

	// dispaly message
	return showMessage(lang.personal_update, 'success')

}

/************************************************
* UPDATE PERSONAL
* @param {string} newPassword
* @param {string} rePassword 
************************************************/

export const updatePassword = (newPassword, rePassword)  => async (dispatch, getState) => {

	// get user id
	const {uid} = auth.currentUser || {}

	// get state
	const {language: {data: lang}, user} = getState()

	// check if loading
	if (user.loading || !uid)
		return false
		

	// password validation
	if (!VALIDATOR.password.exp.test(newPassword))
		return showMessage(lang.password_validation)

	// validate match passwords
	if (newPassword !== rePassword)
		return showMessage(lang.passwords_not_match)


	// start loading
	dispatch({
		type: USER_LOADING,
		data: true
	})

	// triger the request
	let result = await auth.currentUser.updatePassword(newPassword)
		.catch(err => err)
	
	// start loading
	dispatch({
		type: USER_LOADING,
		data: false
	})

	if (result instanceof Error)
		return showMessage(result.code)

	// dispaly message
	return showMessage(lang.password_update, 'success')

}


/************************************************
* FETCH REPORTS
* @param {object} range 
************************************************/
export const fetchReports = (project, range) => (_, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid)
		return false

	let totals = []
	let exportData = []
	let reports = []

	const {projects} = getState()

	let ref = store.reports
		.where('week', '>=', range.startDate)
		.where('week', '<=', range.endDate)
		.orderBy('week')

	if (project !== 'all')
		ref = ref.where('project', '==', project)

	return ref
		.get()
		.then(async snapshot => { 

			snapshot.docs.map(item => {

				let report = item.data()

				reports.push(report)

				let project = projects.list.find(item => item.objectId === report.project) || {}

				if (!totals[report.project] && !exportData[report.project]) {

					totals[report.project] = {}

					exportData[report.project] = {
						project: project.name,
						data: []
					}

				}


				let totalHours = report.monday + report.tuesday + report.wednesday + report.thursday + report.friday + report.saturday + report.sunday

				exportData[report.project].data.push({
					owner:			report.owner,
					project: 	project.name,
					total: 			totalHours,
					monday:			report.monday,
					tuesday:		report.tuesday,
					wednesday: 	report.wednesday,
					thursday:		report.thursday,
					friday:			report.friday,
					saturday:	 	report.saturday,
					sunday:			report.sunday,
					period:			report.period
				})
				
				if (totals[report.project] && !totals[report.project][report.owner])
					totals[report.project][report.owner] = {
						monday: 		0,
						tuesday: 		0,
						wednesday: 	0,
						thursday: 	0,
						friday: 		0,
						saturday: 	0,
						sunday: 		0,
						total: 			0,
						count:		 	0
					}
					
				 
					totals[report.project][report.owner].total 			+= totalHours
					totals[report.project][report.owner].monday 			+= report.monday
					totals[report.project][report.owner].tuesday 		+= report.tuesday
					totals[report.project][report.owner].wednesday 	+= report.wednesday
					totals[report.project][report.owner].thursday 		+= report.thursday
					totals[report.project][report.owner].friday 			+= report.friday
					totals[report.project][report.owner].saturday 		+= report.saturday
					totals[report.project][report.owner].sunday 			+= report.sunday

				return	totals[report.project][report.owner].count += 1 
			
			})

			let users =  []

			// init process
			 Object.keys(totals).map( key => {
				return Object.keys(totals[key]).map(user => {
					return users.push( store.users.doc(user).get().then( snapshot => snapshot.data()) )
				})
			})

		
			// await for process
			users = await Promise.all(users)

			exportData = Object.values(exportData)

			exportData.map(report => {

				 report.data.map(item	=> item.name	= users.find(i => i.objectId === item.owner)?.name)

				return report.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

			})

			return {totals, exportData, users, reports}

		})

}


/************************************************
* FETCH REPORTS
* @param {string} id 
************************************************/
export const fetchUserReports = (id) => (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid) 
		return false

	const {reports} = getState()
	
	if (reports.loading || reports.noMoreToLoad)
		return false

	let query = store.reports
	
	query = query.where('owner', '==', id)
	query = query.orderBy('week', 'desc')
	
	dispatch({type: REPORTS_LOADING})

	if (reports.list.length)
		return query
			.startAfter(reports.list[reports.list.length - 1].createdAt)
			.limit(REPORTS_PER_REQUEST)
			.get()
			.then(snapshot => {
				
				if (snapshot.empty)
					return dispatch({type: REPORTS_LOADED_ALL})

				let items = snapshot.docs.map(doc => doc.data())

				dispatch({
					type: REPORTS_LOADED_MORE,
					data: items
				})
				
				if (items.length < REPORTS_PER_REQUEST)
					dispatch({type: REPORTS_LOADED_ALL})

			})
	else
		return query
			.limit(REPORTS_PER_REQUEST)
			.get()
			.then(snapshot => {

				if (!snapshot.empty)
					query = query.endBefore(snapshot.docs[0])

				if (unsubscribe.reports)
				unsubscribe.reports()
					
				unsubscribe.reports = query.onSnapshot(snapshot => 
					snapshot.docChanges().forEach(change => {
						if (change.type === 'added')
							dispatch({
								type: REPORTS_NEW_ITEM,
								data: change.doc.data({serverTimestamps: 'estimate'})
							})
					})
				)

				if (snapshot.empty)
				{
					dispatch({type: REPORTS_LOADED})
					dispatch({type: REPORTS_LOADED_ALL})
					return false
				}

				let items = snapshot.docs.map(doc => doc.data())

				dispatch({
					type: REPORTS_LOADED,
					data: items
				})

				if (items.length < REPORTS_PER_REQUEST)
					dispatch({type: REPORTS_LOADED_ALL})

			})

}


/************************************************
* CREATE NEW USER REPORT
* @param {object} data 
************************************************/

export const createUserReport = (user, report)  => async (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid) 
		return false

	const {language:{data: lang}} = getState()

	let check = await store.reports
	.where('period', '==', report.week)
	.where('owner', '==', user.objectId)
	.limit(1)
	.get()
	.then(snapshot => !snapshot.empty)

	if (!user.project)
		return showMessage(lang.project_not_assign)

	if (check)
		return showMessage(lang.report_already_exist)

	dispatch({
		type: USER_LOADING,
		data: true
	})

	report.project = user.project
	report.owner = user.objectId

	// triger the request
	let response = await request('users/reports/create', report)


	dispatch({
		type: USER_LOADING,
		data: false
	})

	// dispaly message
	showMessage(lang[response.message] || response.message, response.type)

	return response.type
	
}


/************************************************
* UPDATE USER REPORT
* @param {object} data 
************************************************/

export const updateUserReport = report  => async (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid || !report.objectId) 
		return false

	const {language:{data: lang}} = getState()

	dispatch({
		type: USER_LOADING,
		data: true
	})

	// triger the request
	let response = await request('users/reports/update', report)

	dispatch({
		type: USER_LOADING,
		data: false
	})

	if (response.type === 'success')
		dispatch({
			type: REPORTS_UPDATED_ITEM,
			data: report
		})


	// dispaly message
	showMessage(lang[response.message] || response.message, response.type)

	return response.type
	
}


/************************************************
* CHECK USER REPORTS EXIST
* @param {string} owner 
* @param {string} week
* @returns {object}
************************************************/

export const checkUserReport = (owner, week)  => (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid || !owner || !week) 
		return false

	return store.reports
	.where('period', '==', week)
	.where('owner', '==', owner)
	.limit(1)
	.get()
	.then(snapshot => !snapshot.empty ? snapshot.docs[0].data()	: {})
	.catch(() => ({}))

}