import React from 'react'
import {Switch, Route} from 'react-router-dom'

import List from './List'
import New from './New'
import View from './Preview'

export default function Reports(props) {

	return(
		<>
			<Switch>
				<Route exact path={['/projects', '/projects/list']} component={List} />
				<Route path={'/projects/create'} component={New} />
				<Route path={'/projects/:cid?'} component={View} />
			</Switch>
		</>
		
	)
}