import React from 'react'
import {useParams, Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {fetchUserReports} from '../../../../../actions/user'
import moment from 'moment'



export default function View(props) {

	const dispatch 	= useDispatch()
	const {uid}			= useParams()
	
	const {data: lang} 	= useSelector(state => state.language)
	const projects 		= useSelector(state => state.projects) 
	const reports				= useSelector(state => state.reports)




	return(
		<>

			<div className="box  mb-3">

				<div className="list-group-flush">

					{reports.list.map(report => {
						
						let project 		= projects.list.find(item => item.objectId === report.project) || {}
						let totalHours = report.monday + report.tuesday + report.wednesday + report.thursday + report.friday + report.saturday + report.sunday

						return (
							<div className="list-group-item align-items-center" key={report.objectId}>
								
								<div className="row">

									<div className="col-9 col-sm-6 col-md-6 mb-2 mb-md-0 d-grid align-items-center">
										<Link to={`reports/${report.objectId}`}><b>{report.period}</b></Link>
										<div className="text-muted"><small>{project.name}</small></div>
									</div>

									<div className="col-12 order-2 order-md-1 col-md-3 mb-2 mb-md-0 d-grid align-items-center">
										<small>{totalHours} {lang.hours}</small>
									</div>

									<div className="col-3 order-1 order-md-3 col-sm-6 col-md-3 mb-2 mb-md-0 d-grid align-items-center justify-content-end text-end flex-grow-0">
										<small>{moment(report.createdAt.toMillis()).format('DD MMMM YYYY')}</small>
									</div>

								</div>

							</div>
						)
					})}

					{(!reports.list.length && reports.noMoreToLoad) &&
						<div className="list-group-item text-danger">{lang.no_reports}</div>
					}

					{!reports.noMoreToLoad &&
						<div className="list-group-item text-center">
							<button className="btn btn-sm btn-primary" onClick={() => dispatch(fetchUserReports(uid))} disabled={reports.loading}>
								{reports.loading ? <i className="fas fa-spin fa-spinner-third" /> : lang.load_more}
							</button>
						</div>
					}


				</div>

			</div>
		</>
	)
}
 