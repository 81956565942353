/************************************************
 * IMPORTS
 ***********************************************/

import {createBrowserHistory} from 'history' 
import {toast} from 'react-toastify'
import {auth} from './firebase'
import {API_URL} from '../constants'
import axios from 'axios'
 
/************************************************
* Create browser history
***********************************************/
 
export const history = createBrowserHistory()


/************************************************
* XHR Request
* @param {string} url URL where the request is performed
* @param {object} data Additional request data
* @param {boolean} needsAuth Should request send authorization headers
***********************************************/

 export const request = async (url, data = {}, needsAuth = true) => {

	let options = {}
	
	if (needsAuth)
	{

		// get currnet user
		const {currentUser} = auth

		if (!currentUser)
			return {error: 'not_authenticated'}

		// set token
		options.headers = {Authorization: await currentUser.getIdToken()}

	}

	let response = await axios.post(API_URL + url, data, options)
		.then(response => response.data)
		.catch(() => ({error: 'api_failed'}))

	if (typeof response !== 'object')
		response = {error: 'api_failed'}

	return response

}

/************************************************
* Method for triming text by length
* @param {string} text
* @param {number} maxLength 
***********************************************/

export const trimText = ( text, maxLength ) => {

	let trimText = text.substring( 0, maxLength )

	if ( text.length > maxLength )
		trimText += '...'

	return trimText
}	



/************************************************
* Method displaying message
***********************************************/

export const showMessage = (message, type = 'error') => {

	toast[type](message, {
		autoClose: 2500,
		draggable: false,
		closeOnClick: true,
		pauseOnHover: true
	})

	return null
	
}

/************************************************
* Method geting prefix succesor
***********************************************/

export const prefixSuccessor = prefix => {

	let limit = prefix;
	
  while ( limit.length > 0 ) {

		const index = limit.length - 1;
		
    if ( limit[index] === '\xff' )
      limit = limit.slice(0, -1)
    else {
      limit = limit.substr(0, index) + String.fromCharCode(limit.charCodeAt(index) + 1)
      break
		}
		
	}
	
	return limit;
	
}



/************************************************
* Method to capitalize each word in a string
* @param {string} str Text to capitalize
***********************************************/

 export const toTitleCase = str => {
	return str.replace(/\w\S*/g, function(txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	})
}