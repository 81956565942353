import React from 'react'
import {NavLink} from 'react-router-dom'
import {useSelector} from 'react-redux'

export default function SideBar(props) {

	const {data: lang} 	= useSelector(state => state.language)
	const user 					= useSelector(state => state.user)

	return(
		<ul className="list-group mb-3">

			<NavLink className="list-group-item" exact to="/">
				<i className="fas fa-fw fa-home" /> {lang.home}
			</NavLink>

			{user.admin ?	<>

				<NavLink className="list-group-item" to="/employees">
					<i className="fas fa-fw fa-user-friends" /> {lang.employees}
				</NavLink>

				<NavLink className="list-group-item" to="/reports">
					<i className="fas fa-fw fa-clipboard-list-check" /> {lang.reports}
				</NavLink>
				
				<NavLink className="list-group-item" to="/projects">
					<i className="fas fa-fw fa-list-ul" /> {lang.projects}
				</NavLink>

			</> : <>
			
				<NavLink className="list-group-item" to="/my_reports">
					<i className="fas fa-fw fa-clipboard-list-check" /> {lang.my_reports}
				</NavLink>
			
			</>}

			<NavLink className="list-group-item" to="/account">
				<i className="fas fa-fw fa-cog" /> {lang.account}
			</NavLink>
	
		</ul>
	)
}