import React,{useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'
import {useParams} from 'react-router-dom'

import {
	checkUserReport,
	createUserReport, 
	updateUserReport
} from '../../../../../actions/user'

export default function New(props) {

	const dispatch = useDispatch()
	const {uid} 	 = useParams()

	const initialState = {
		week			: '',
		weekCount	: 0,
		monday		: 0,
		tuesday		: 0,
		wednesday	: 0,
		thursday	: 0,
		friday		: 0,
		saturday	: 0,
		sunday		: 0
	}

	const user					= useSelector(state => state.user)
	const users					= useSelector(state => state.users)
	const {data: lang}  = useSelector(state => state.language)
	const selectedUser 	= users.list.find(item => item.objectId === uid) || {}

	const [loading, setLoading] = useState(false)
	const [exist, setExist] 		= useState({})
	const [state, changeState] 	= useState(initialState)

	const TODAY = moment().format('YYYY-MM-DD')


	const [range, setRange] = useState({
		startDate: moment().format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD')
	})

	

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onChange = e => setState({[e.target.name]: e.target.value})



	useEffect(() => {

		if (!state.week) {
			setLoading(false)
			setExist({})
			return false
		}
			
		const check = async () => {

			setLoading(true)
			let response =	await dispatch(checkUserReport(uid, state.week))

			setLoading(false)

			if(response.objectId)
			return	setState({
					week			: response.period,
					monday		: response.monday,
					tuesday		: response.tuesday,
					wednesday	: response.wednesday,
					thursday	: response.thursday,
					friday		: response.friday,
					saturday	: response.saturday,
					sunday		: response.sunday,
					objectId	: response.objectId
				})

			

			setState({
				...initialState,
				objectId: "",
				week: state.week,
			})

		}

		check()

		// eslint-disable-next-line
	}, [dispatch, uid, state.week])

	const onSubmit = async () => {

		if (state.objectId)
			 return await dispatch(updateUserReport({...state}))
			
		let response  = await dispatch(createUserReport(selectedUser, {...state}))

		if (response === 'success')
			setState(initialState)

	}

	const onInputChange = e => {

		let start = moment(e.target.value).startOf('isoWeek')
		let end 	= moment(e.target.value).endOf('isoWeek')

		let week = start.format('DD MMMM YYYY') + ' - ' + end.format('DD MMMM YYYY')

		setState({week: week})

		setRange({...range, [e.target.name]: e.target.value})

	}

	return(
		<>

			<div className="box p-3">

				<div className="input-group input-group">
					<span className="input-group-text">{lang.week}</span>
					<input className="form-control"  disabled defaultValue={state.week} />
					<input type="date" className="form-control" value={range.endDate} max={TODAY} name="endDate" onChange={onInputChange} />
				</div>

				<hr />

				{!loading && state.week &&
					<div className="row mt-3">

						{/* Monday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.monday}</span>
								<input type="number" className="form-control"  name="monday" value={state.monday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						{/* Tuesday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.tuesday}</span>
								<input type="number" className="form-control"  name="tuesday" value={state.tuesday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>	

						{/* Wednesday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.wednesday}</span>
								<input type="number" className="form-control"  name="wednesday" value={state.wednesday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>	

						{/* Thursday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.thursday}</span>
								<input type="number" className="form-control"  name="thursday" value={state.thursday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						{/* Friday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.friday}</span>
								<input type="number" className="form-control"  name="friday" value={state.friday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>	

						{/* Saturday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.saturday}</span>
								<input type="number" className="form-control"  name="saturday" value={state.saturday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						{/* Sunday */}
						<div className="col-12 col-md-12 mb-3">
							<div className="input-group input-group">
								<span className="input-group-text">{lang.sunday}</span>
								<input type="number" className="form-control"  name="sunday" value={state.sunday} onChange={onChange} />
								<span className="input-group-text">{lang.hours}</span>
							</div>
						</div>

						<div className="col-12 mb-3 text-end">
							<button className="btn btn-success" onClick={onSubmit} disabled={user.loading}>
								{user.loading ? <i className="fas fa-spin fa-spinner-third" /> : state.objectId ? lang.save : lang.submit}	
							</button>
						</div>

					</div>
				}

				{!state.week && !exist.objectId &&
					<div className="text-danger">{lang.week_not_selected}</div>
				}


				{loading &&
					<div className="text-center">
					 	<span className="sr-only">Loading...</span>
					</div>
				}

			</div>

		</>
	)

}