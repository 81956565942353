import React from 'react'
import {useSelector} from 'react-redux'

import NewReport from './NewReport'
import LastReport from './LastRaport'

export default function Reports(props) {

	const user					= useSelector(state => state.user)
	
	return(
		<div className="row">
			
			{user.project && 
				<div className="col-12">
					<NewReport />
				</div> 
			}

			<div className="col-12 mt-3 d-flex flex-column">
				<LastReport />
			</div>
			
		</div>
	)
}