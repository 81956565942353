
import {VALIDATOR} from '../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {

	key:																'en',
	descriptor:											 		'English',


	hours:															'hours',
	minutes:													 	'minutes',
	min:																'min',
	h:																 	'h',
	week:														 		'Week',
	monday:													 		'Monday',
	tuesday:													 	'Tuesday',
	wednesday:												 	'Wednesday',
	thursday:												 		'Thursday',
	friday:													 		'Friday',
	saturday:												 		'Saturday',
	sunday:													 		'Sunday',
	yesterday:												 	'Yesterday',
	fullDays:												 		[ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ],
	shortDays:												 	[ 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun' ],
	shortDaysInit:										 	[ 'M', 'T', 'W', 'T', 'F', 'S', 'S' ],
	fullMonths:											 		[ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
	shortMonths:											 	[ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],

	'auth/user-not-found':						 	'Specified email was not found in out database.',
	'auth/too-many-requests':				 		'Too many failed attempts. Please try again later.',
	'auth/wrong-password':						 	'The current password is not correct.',
	'auth/invalid-email':						 		'The specified email is not valid.',
	'auth/email-already-in-use':			 	'Specified email is already registered.',
	'storage/unauthorized':					 		'Not enough rights. Refresh and retry.',
	'storage/canceled':							 		'Upload has been cancelled. Try again.',
	'storage/unknown':								 	'Unknown error occurred. Try again.',

	api_failed:											 		'An error occurred. Please try again.',
	api_success:										 		'The action was completed sucessfully!',
	invalid_payload:									 	'Oops! Invalid payload',
	method_forbidden:								 		'Oops! Action forbidden',
	unauthorized_request:						 		'Oops! Unauthorized request',
	invalid_identifier:							 		'Oops! The message identifier is not valid',
	user_created:												'User created succesfuly',
	user_update:												'User update succesfuly',

	project_created:										'Project created succesfuly',
	project_update:											'Project update succesfuly',

	invalid_open_time_format:				 		'Open time it\'s invalid! Currect format HHmm.',
	invalid_close_time_format:			 		'Close time it\'s invalid! Currect format HHmm.',
	invalid_startBreak_time_format:	 		'Start break time it\'s invalid! Currect format HHmm.',
	invalid_endBrak_time_format:			 	'End break time it\'s invalid! Currect format HHmm.',
	invalid_startBreak_position:			 	'Start break must be between open and close schedule.',
	invalid_endBreak_position:				 	'End break must be between open and close schedule and bigger then start break.',
	all_fields_required:							 	'All fields are required, please fill all fields.',
	inavlid_service_name:						 		'Name it\'s not fill or it\'s invalid',
	invalid_service_duration:				 		'Duration it\'s not fill or it\'s invalid',
	invalid_service_price:						 	'Price it\'s not fill or it\'s invalid',
	fb_login_failed:									 	'Facebook authentication was',
	failed_get_notif_token:					 		'Failed to get push token for push notification!',
	name_validation:       							`Your name must be between ${VALIDATOR.email.length[0]} and ${VALIDATOR.email.length[1]} characters long`,
	phone_validation:										'The specified phone number is not valid',
	email_validation:      				 			'The specified email is not valid',
	password_validation:    						`Your password must be between ${VALIDATOR.password.length[0]} and ${VALIDATOR.password.length[1]} characters long`,
	passwords_not_match:								'Password don\'t mach',

	welcome:														'Welcome',
	connect:													 	'Connect',
	logout:													 		'Logout',

	employees:													'Employees',
	reports:													 	'Reports',
	account:													 	'Account',
	home:																'Home',
	my_reports:													'My Reports',
	new_report:													'New Report',
	last_report:												'Last Report',
	total:															'Total',
	personal:														'Personal',
	general:														'General',
	security:														'Security',
	fetch:															'Fetch',
	search:															'Search',
	clear:															'Clear',
	contains:														'Contains',
	status:															'Status',
	submitted:													'Submitted',
	not_submitted:											'Not submitted',
	info:																'Info',

	no_report:													'No report found',
	no_report_register:									'There are no report register yet',

	create:													 		'Create',
	save:																'Save',
	submit:															'Submit',

	create_user:											 	'Create user',
	edit_user:													'Edit user',

	load_more:												 	'load more',

	no_users:												 		'No users found',
	week_not_selected:								 	'Week not selected',
	select_week:											 	'Select week',
	report_already_exist:						 		'Report already exist,',
	report_created:									 		'Report created succesfully!',
	report_update:											'Report update succesfuly',
	total_hours:										 		'Total hours',
	project:												 		'Project',
	created_at:											 		'Created At',
	register_date:										 	'Register Date',
	blocked:													 	'Blocked',
	uid:															 	'UID',
	language:														'Language',
	admin:															'Admin',
	add_user:														'Add user',

	email:														 	'Email',
	password:												 		'Password',
	new_password:												'New Password',
	re_password:												'Re Password',
	name:														 		'Name',
	phone:														 	'Phone',

	not_in_a_project:								 		'You are not in a project yet',
	not_assigned:										 		'Not assigned',
	no_reports:													'No reports found',

	projects:														'Projects',
	no_projects:												'No projects found',
	add_project:												'Add project',
	edit_project:												'Edit project',

	personal_update:										'Personal info update succesfuly.',
	password_update:										'Password update succesfuly',

	project_not_assign:									'Projects not assigned',
	select_status:											'Select status',
} 